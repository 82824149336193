import React from 'react';
import { useSelector } from 'react-redux';

import './Ecology.css';
import { ecologySelector } from '../../store/ducks/homepage/selectors/homepageSelector';
import { Link } from 'react-router-dom';

export function Ecology() {
    const { ecology: ecologyPosts }: { ecology: any[] } = useSelector(
        ecologySelector
    );

    return (
        <>
            <section className="ecology page__ecology _purple">
                <div className="ecology__container">
                    <div className="ecology__content">
                        {ecologyPosts.map((ecoPost: any) => {
                            return (
                                <Link
                                    to={ecoPost.pathTo}
                                    className="card"
                                    key={ecoPost.id}
                                >
                                    <div className="card__img-ibg">
                                        <img src={ecoPost.image} alt="img" />
                                    </div>
                                    <div className="card__inner">
                                        <span className="card__tag">
                                            {ecoPost.categories}
                                        </span>
                                        <h3 className="card__title">
                                            {ecoPost.title}
                                        </h3>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    );
}
