import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './NewsList.css';
import { getHomepage, getPosts } from '../../store/ducks/homepage/actions';
import { newsSelector } from '../../store/ducks/homepage/selectors/homepageSelector';

export function NewsList() {
    const dispatch = useDispatch();

    const status = useSelector(
        (state: { homepage: { status: string } }) => state.homepage.status
    );

    const {
        news,
    }: {
        news: {
            id: number;
            date: string;
            title: string;
            type: string;
            tag: string;
            slug: string;
        }[];
    } = useSelector(newsSelector);

    useEffect(() => {
        if (status === 'IDLE') {
            dispatch(
                getPosts({
                    offset: news.length,
                })
            );
        }
    }, []);

    return (
        <>
            <ul className="news-list news__news-list">
                {news.map(n => {
                    return (
                        <li className="news-list__item" key={n.id}>
                            <Link
                                to={`/news/${n.slug}`}
                                className="news-list__link"
                            >
                                <span className="news-list__data">
                                    {n.date}
                                </span>
                                <h3 className="news-list__title">{n.title}</h3>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}
