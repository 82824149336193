import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { HomeFirstScreen } from 'components/HomeFirstScreen/HomeFirstScreen';
import { NewsList } from 'components/NewsList/NewsList';
import { NewsCards } from 'components/NewsCards/NewsCards';
import { InfiniteLine } from 'components/InfiniteLine/InfiniteLine';
import { Ecology } from 'components/Ecology/Ecology';
import { Cites } from 'components/Cites/Cites';
import { Instructions } from 'components/Instructions/Instructions';
import useSSR from 'use-ssr';
// import { connect } from 'react-redux';
// import { State, Sneakers as SneakersType } from 'types';
// import { fetchHomepage } from 'store/ducks/homepage/actions';
// import { fetchNews } from 'store/ducks/newsOne/actions';
// import { getHomepage, isLoading } from 'store/ducks/homepage/selectors';
// import { HomeStub } from './Home.stub';
// import { getNews } from '../../components/News/actions';
// Main,
// Newsfeed,
// Articles,
// Subscription,
// Others,
// Person,
// PageMeta,
// Page,
'components';

import {
    getHomepage,
    getHomepageIdle,
    getPosts,
    getPostsIdle,
} from 'store/ducks/homepage/actions';
import { Post, StateStatus } from 'types/models';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

type Props = {};

const Home = (props: Props) => {
    const [browser, setBrowser] = useState(true);
    // const Home: React.FC = () => {
    const dispatch = useDispatch();

    const { status, postsAllNumber, posts } = useSelector(
        (state: {
            homepage: {
                status: StateStatus;
                postsAllNumber: number;
                posts: Post[];
            };
        }) => state.homepage
    );

    useEffect(() => {
        if (status == 'IDLE') {
            dispatch(getHomepage());
        }
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth < 1024) {
                setBrowser(false);
            } else {
                setBrowser(true);
            }
            const resizeThrottler = () => {
                if (window.innerWidth < 1024) {
                    setBrowser(false);
                } else {
                    setBrowser(true);
                }
            };

            window.addEventListener('resize', resizeThrottler, false);
        }
    }, []);

    function getLoadMorePosts() {
        dispatch(
            getPosts({
                offset: posts.length,
            })
        );
    }

    return (
        <>
            <Helmet>
                <title>Рядом</title>
                <meta property="og:title" content={`Рядом`} />
                <meta property="twitter:title" content={`Рядом`} />
                <meta name="description" content={``} />
                <meta property="og:description" content={``} />
                <meta property="twitter:description" content={``} />
                <meta property="og:image" content={``} />
            </Helmet>
            <main className="page">
                <HomeFirstScreen />
                <section className="news page__news">
                    <div className="news__container">
                        <div></div>
                        <div></div>
                        <div className="news__content">
                            {browser && (
                                <div className="news__wrap">
                                    <Link
                                        to="/news"
                                        className="news-list__link"
                                    >
                                        <h2 className="news__title">Новости</h2>
                                    </Link>

                                    <NewsList />
                                </div>
                            )}

                            <NewsCards />
                        </div>
                        {postsAllNumber !== posts.length && (
                            <button
                                className="news-cards__more"
                                onClick={getLoadMorePosts}
                            >
                                {' '}
                                ещё{' '}
                            </button>
                        )}
                    </div>
                </section>
                <InfiniteLine mod="" />
                <Ecology />
                {/*<Cites />*/}
                <InfiniteLine mod="_2" />
                <Instructions />
            </main>
        </>
    );
};

export default Home;
