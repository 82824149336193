import React from 'react';
import { useSelector } from 'react-redux';
import './HomeFirstScreen.css';
import { homeFirstScreenSelector } from '../../store/ducks/homepage/selectors/homepageSelector';
import { Link } from 'react-router-dom';

export function HomeFirstScreen() {
    const { post } = useSelector(homeFirstScreenSelector);

    if (!post)
        return (
            <section className="first-screen page__first-screen">
                'Loading...'
            </section>
        );

    return (
        <>
            <section className="first-screen page__first-screen">
                <div className="first-screen__container">
                    <Link
                        to={post.pathTo}
                        className="card card--first-screen first-screen__card"
                    >
                        <div className="card__img-ibg">
                            <img src={post.image} alt="img" />
                        </div>
                        <div className="card__inner">
                            <span className="card__tag">{post.categories}</span>
                            <h3 className="card__title">{post.title}</h3>
                        </div>
                    </Link>
                </div>
            </section>
        </>
    );
}
