import { createSelector } from 'reselect';
import { Post } from 'types/models';

import {
    getFullPath,
    getParentSlug,
    mapArrayNumberToStringValues,
    removeAllHTMLAndUnicodeSymbols,
} from '../../../helpers/helpers';
import { displayDate } from '../../../helpers/displayDate.helpers';

const posts = (state: { homepage: { posts: Post[] } }) => state.homepage.posts;

const categories = (state: { initdata: { categories: any } }) =>
    state.initdata.categories;

const tags = (state: { initdata: { tags: any } }) => state.initdata.tags;

export const homeFirstScreenSelector = createSelector(
    [posts, categories, tags],
    (posts, categories, tags) => {
        let data: any[] = [];
        if (
            posts &&
            posts.length &&
            Object.keys(categories).length &&
            Object.keys(tags).length
        ) {
            let index = 0;
            let type = 'post',
                parentSlug = getParentSlug(posts[index], categories),
                object = {
                    id: posts[index].id,
                    categories: mapArrayNumberToStringValues(
                        posts[index].categories,
                        categories
                    ),
                    title: removeAllHTMLAndUnicodeSymbols(
                        posts[index].title.rendered
                    ),
                    image: posts[index].images.full,
                    tag: mapArrayNumberToStringValues(posts[index].tags, tags),
                    type: type,
                    pathTo: getFullPath([
                        'posts',
                        parentSlug,
                        posts[index].slug,
                    ]),
                    slug: posts[index].slug,
                };
            data.push(object);
        }

        return { post: data[0] };
    }
);

export const homepageSelector = createSelector(
    [posts, categories, tags],
    (posts, categories, tags) => {
        let renderPosts: any[] = [];

        if (
            posts &&
            posts.length &&
            Object.keys(categories).length &&
            Object.keys(tags).length
        ) {
            posts.map((post: Post, index) => {
                if (index !== 0) {
                    let type = 'post',
                        parentSlug = getParentSlug(post, categories),
                        object = {
                            id: post.id,
                            categories: mapArrayNumberToStringValues(
                                post.categories,
                                categories
                            ),
                            image: post.images.full || '',
                            title: removeAllHTMLAndUnicodeSymbols(
                                post.title.rendered
                            ),
                            description: removeAllHTMLAndUnicodeSymbols(
                                post.excerpt.rendered
                            ),
                            tag: mapArrayNumberToStringValues(post.tags, tags),
                            type: post.type || type,
                            pathTo: getFullPath([
                                'posts',
                                parentSlug,
                                post.slug,
                            ]),
                            slug: post.slug,
                        };

                    renderPosts.push(object);
                }
            });
        }

        return { cards: renderPosts };
    }
);

const news = (state: { homepage: { news: any[] } }) => state.homepage.news;

export const newsSelector = createSelector([news], news => {
    let renderNews: any[] = [];
    if (news && news.length) {
        news.map((n: any, index: number) => {
            let tag = '',
                object = {
                    id: n.id,
                    date: displayDate(n.date),
                    title: removeAllHTMLAndUnicodeSymbols(n.title.rendered),
                    tag: tag,
                    type: n.type,
                    slug: n.slug,
                };

            renderNews.push(object);
        });
    }
    return { news: renderNews };
});

const ecology = (state: { homepage: { ecology: any[] } }) =>
    state.homepage.ecology;

export const ecologySelector = createSelector(
    [ecology, categories, tags],
    (ecology, categories, tags) => {
        let ecologyPosts: any[] = [];

        if (
            ecology &&
            ecology.length &&
            Object.keys(categories).length &&
            Object.keys(tags).length
        ) {
            ecology.map((eco: any) => {
                let type = 'post',
                    parentSlug = getParentSlug(eco, categories),
                    object = {
                        id: eco.id,
                        categories: mapArrayNumberToStringValues(
                            eco.categories,
                            categories
                        ),
                        image: eco.images.full || '',
                        title: removeAllHTMLAndUnicodeSymbols(
                            eco.title.rendered
                        ),
                        pathTo: getFullPath(['posts', parentSlug, eco.slug]),
                        slug: eco.slug,
                        tag: mapArrayNumberToStringValues(eco.tags, tags),
                        type: eco.type || type,
                    };

                ecologyPosts.push(object);
            });
        }

        return { ecology: ecologyPosts };
    }
);

const cites = (state: { homepage: { cites: any[] } }) => state.homepage.cites;

export const citesSelector = createSelector([cites, tags], (cites, tags) => {
    let renderCites: any[] = [];
    if (cites && cites.length && Object.keys(tags).length) {
        cites.map((cite: Post) => {
            let type = 'cite',
                parentSlug = tags[cite.tags[0]].slug,
                object = {
                    id: cite.id,
                    image: cite.images.full || '',
                    title: removeAllHTMLAndUnicodeSymbols(cite.title.rendered),
                    description: removeAllHTMLAndUnicodeSymbols(
                        cite.excerpt.rendered
                    ),
                    pathTo: getFullPath(['tags', parentSlug, cite.slug]),
                    type: cite.type || type,
                    slug: cite.slug,
                };
            renderCites.push(object);
        });
    }
    return { cites: renderCites };
});
