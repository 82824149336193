import React, { useEffect, useState } from 'react';
import './NewsCards.css';
import { useSelector } from 'react-redux';
import { homepageSelector } from '../../store/ducks/homepage/selectors/homepageSelector';
import { Link } from 'react-router-dom';
import { NewsList } from '../NewsList/NewsList';

// export const withNews = (Component) =>{
//     return <>
//         Component
//     </>
// }

export function NewsCards() {
    const [browser, setBrowser] = useState(true);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth > 1024) {
                setBrowser(false);
            }
        }
    }, []);

    const { cards: posts }: { cards: any[] } = useSelector(homepageSelector);

    return (
        <>
            <div className="news-cards news__news-cards">
                <div className="news-cards__wrapper">
                    {posts.map((post, index) => {
                        let newsContent = (
                            <>
                                <div className="news__wrap">
                                    <h2 className="news__title">Новости</h2>
                                    <NewsList />
                                </div>
                                <div></div>
                                <div></div>
                            </>
                        );

                        return (
                            <React.Fragment key={index}>
                                {index === 0 ? (
                                    <>
                                        <Link
                                            to={post.pathTo}
                                            className="card"
                                            key={post.id}
                                        >
                                            <div className="card__img-ibg">
                                                <img
                                                    src={post.image}
                                                    alt="img"
                                                />
                                            </div>
                                            <div className="card__inner">
                                                <span className="card__tag">
                                                    {post.categories}
                                                </span>
                                                <h3 className="card__title">
                                                    {post.title}
                                                </h3>
                                                <p className="card__text">
                                                    {post.description}
                                                </p>
                                            </div>
                                        </Link>
                                        {browser && newsContent}
                                    </>
                                ) : (
                                    <Link
                                        to={post.pathTo}
                                        className="card"
                                        key={post.id}
                                    >
                                        <div className="card__img-ibg">
                                            <img src={post.image} alt="img" />
                                        </div>
                                        <div className="card__inner">
                                            <span className="card__tag">
                                                {post.categories}
                                            </span>
                                            <h3 className="card__title">
                                                {post.title}
                                            </h3>
                                            <p className="card__text">
                                                {post.description}
                                            </p>
                                        </div>
                                    </Link>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
